import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { rhythm } from '../utils/typography'
import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1>Oh Noes!</h1>

        <Image
          fixed={data.illustration.childImageSharp.fixed}
          style={{
              marginRight: rhythm(1 / 2),
              marginBottom: 20,
              minWidth: 200,
          }}
          imgStyle={{
              borderRadius: `50%`,
          }}
        />

        <p>You just hit a route that doesn&#39;t exist... try to navigate back!</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    illustration: file(absolutePath: { regex: "/fourohfour_dark.jpg/" }) {
      childImageSharp {
        fixed(width: 512, height: 512, quality: 95) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
